<template>
  <div class="sensor-network">
    <canvas ref="canvas" :width="size.width" :height="size.height" />
    <Loader v-if="$asyncComputed.sensorsData.updating" />
    <timelapse
      :current-date="topFilters.from"
      :enable="enable"
      :range="range"
      :loading="$asyncComputed.sensorsData.updating"
      @toggle="enable = $event"
      @range="range = $event"
    />
  </div>
</template>

<script>
import { forceSimulation as d3_forceSimulation } from 'd3-force'
import { scalePow as d3_scalePow, scaleOrdinal as d3_scaleOrdinal } from 'd3-scale'
import { median as d3_median } from 'd3-array'
import { pointer as d3_pointer, select as d3_select } from 'd3-selection'
import {
  forceManyBody as d3_forceManyBody,
  // forceCollide as d3_forceCollide,
  forceLink as d3_forceLink,
  // forceCenter as d3_forceCenter,
  // forceRadial as d3_forceRadial,
  forceX as d3_forceX,
  forceY as d3_forceY,
} from 'd3-force'
import { drag as d3_drag } from 'd3-drag'
import { common_tangent_line } from '@/utils/circleUtils'
import { forceCollideOptional } from '@/utils/simulationUtils'
import { getTopEntities, getSensors } from '@/services/api'
import Size from '@/mixins/Size'
import Loader from './Loader.vue'
import { makeTree } from '@/utils/treeUtils'
import GetFilterBySetting from '../mixins/GetFilterBySetting'
import FilteredSensor from '../mixins/FilteredSensor'
import Timelapse from './Timelapse.vue'
import { getEvolutive } from '../services/api'
import IndicatorMixin from '../mixins/IndicatorMixin'
import { getPlayingDate, getResponseFromEvolutive } from '../utils/periodUtils'

const GROUP_COLORS = ['#faa', '#9af', '#8a8', '#fd8', '#e6d']
let colorScale = d3_scaleOrdinal(GROUP_COLORS)
export default {
  components: { Loader, Timelapse },
  mixins: [Size, GetFilterBySetting, FilteredSensor, IndicatorMixin],
  props: {
    dimension: {
      default: 'vSessions',
    },
    useFakeData: {
      default: false,
    },
  },
  data() {
    return {
      range: 0,
      enable: false,
    }
  },
  watch: {
    size() {
      if (this.sensorsData) this.draw()
    },
    sensorsData() {
      this.draw()
    },
    dimension() {
      this.draw()
    },
  },
  mounted() {
    // this.draw()
  },
  asyncComputed: {
    async sensorsData() {
      /** 
       * Issue #347: if we fetch >3 months, we need to wait for indicators to be fetched
       * sessionKpis is different from the range date (see issue #314)
       */
      if (this.updatingIndicators) return []
      const isEvolutive = this.enable
      const playingDate = getPlayingDate(this.topFilters.from, this.range)
      const sessionKpis = this.sessionKpis
      if (!this.topFilters.from || !this.topFilters.to || sessionKpis.length === 0) return null
      return getSensors(this.topFilters).then(resp => {
        let sensors = resp.map(n => {
          return {
            ...n,
            nodeType: 'LEAF',
          }
        })
        if (isEvolutive)
          return getEvolutive(
            'SESSION',
            'SESSION',
            { ...this.topFilters, country: null },
            sessionKpis,
            {
              aggregations: ['sensor'],
            }
          )
            .then(evolResponse => {
              const response = getResponseFromEvolutive(evolResponse, playingDate)
              return response
            })
            .then(response => {
              sensors.forEach(s => {
                s.values = response.find(d => d.id.sensor === s.id)
              })
              // Filter Retailers null
              return sensors.filter(c => c.values && c.retailer)
            })
        // Return all Entities and we only show the sensor filtered
        return getTopEntities(
          'SESSION',
          { ...this.topFilters, id: ['SESSION'] },
          sessionKpis,
          {
            aggregations: ['sensor'],
          }
        ).then(response => {
          sensors.forEach(s => {
            s.values = response.find(d => d.id.sensor === s.id)
          })
          // Filter Retailers null
          return sensors.filter(c => c.values && c.retailer)
        })
      })
    },
  },
  methods: {
    draw() {
      // let isAbsolute = dimension => {
      //   if (dimension === 'vSessions') return true
      //   else return false
      // }
      let nonEmpties = this.sensorsData.filter(s => {
        return s.values && s.values[this.dimension]
      })
      const methodForCalculateNodes = (() => {
        switch (this.dimension) {
          case 'vDuration':
          case 'vBounceRate':
            return (obj, node) => {
              if (!obj[this.dimension]) obj.isSum = true
              obj[this.dimension] = obj[this.dimension] || 0
              obj.vSessions = obj.vSessions || 0
              obj._ponderateAv = obj._ponderateAv || 0

              obj[this.dimension] += node.isSum
                ? node[this.dimension]
                : node[this.dimension] * node.vSessions
              obj.vSessions += node.vSessions
              return obj
            }
          default:
            return undefined
        }
      })()
      const getDataFromNode = (() => {
        switch (this.dimension) {
          case 'vDuration':
          case 'vBounceRate':
            return obj => {
              return obj[this.dimension] / obj.vSessions
            }
          default:
            return undefined
        }
      })()
      let nodes = makeTree(nonEmpties, this.dimension, {
        reduceCalcuculatedFunction: methodForCalculateNodes,
        getTotal: getDataFromNode,
      })
      nodes.forEach(n => {
        if (n.parent)
          n.color = colorScale(n.retailer || n.parent?.retailer || n.parent?.parent?.retailer)
      })

      // console.log(nodes)
      // console.log(nodes.map(({ name, label }) => ({ name, label })))

      // let extent = d3_extent(nonEmpties, sensor => sensor.values[this.dimension])
      // let median = d3_median(nonEmpties, sensor => sensor.values[this.dimension])
      // let dict = nonEmpties.reduce((dict, s) => {
      //   if (!dict[s.retailer])
      //     dict[s.retailer] = {
      //       id: s.retailer,
      //       nodeType: 'GROUP',
      //       sensors: [],
      //     }
      //   dict[s.retailer].sensors.push(s)
      //   return dict
      // }, {})

      // let groups = Object.values(dict)
      // groups = groups.map((g, i) => {
      //   let sum = g.sensors.reduce((sum, s) => {
      //     return sum + s.values[this.dimension]
      //   }, 0)
      //   let value
      //   if (isAbsolute(this.dimension)) value = sum
      //   else value = sum / g.sensors.length
      //   return {
      //     ...g,
      //     color: GROUP_COLORS[i],
      //     // r: rScale(sum),
      //     value: value,
      //     // link_r: rScale(sum) + 10,
      //     // x: this.size.width / 2 + TR * 1.5 * Math.sin((i / groups.length) * 2 * Math.PI),
      //     // y: this.size.height / 2 + TR * Math.cos((i / groups.length) * 2 * Math.PI),
      //   }
      // })
      // // let groups = this.sensorsData.map(s => s.group)
      // const TR = Math.min(this.size.width / 2, this.size.height / 2) * 0.6
      // groups.forEach((g, i) => {
      //   g.x = this.size.width / 2 + TR * 2 * Math.sin((i / groups.length) * 2 * Math.PI)
      //   g.y = this.size.height / 2 + TR * Math.cos((i / groups.length) * 2 * Math.PI)
      // })
      // nonEmpties.forEach(s => {
      //   // s.r = 8 + 20 * (s.values[this.dimension] / extent[0]) //8 + 14 * Math.random(),s
      //   s.value = s.values[this.dimension]
      // })
      // let total = groups.reduce((total, g) => {
      //   return total + g.value
      // }, 0)
      // let value
      // if (isAbsolute(this.dimension)) value = total
      // else value = total / groups.length
      // const rootNode = {
      //   id: 'ALL',
      //   nodeType: 'ROOT',
      //   centerX: this.size.width / 2,
      //   centerY: this.size.height / 2,
      //   x: this.size.width / 2,
      //   y: this.size.height / 2,
      //   value: value,
      // }

      // let median = d3_median(nodes, sensor => sensor.values[this.dimension])
      let root = nodes.find(n => n.nodeType === 'ROOT')
      root.centerX = this.size.width / 2
      root.centerY = this.size.height / 2
      root.x = this.size.width / 2
      root.y = this.size.height / 2
      root.fx = this.size.width / 2
      root.fy = this.size.height / 2

      const TR = Math.min(this.size.width / 2, this.size.height / 2) * 0.6
      let groups = nodes.filter(n => n.nodeType === 'GROUP')
      groups.forEach((g, i) => {
        g.x = this.size.width / 2 + TR * 2 * Math.sin((i / groups.length) * 2 * Math.PI)
        g.y = this.size.height / 2 + TR * Math.cos((i / groups.length) * 2 * Math.PI)
      })

      let median = d3_median(nodes, sensor => sensor.value)
      let total = nodes.reduce((total, g) => {
        return total + g.value
      }, 0)
      let rScale = d3_scalePow().exponent(0.3).domain([median, total]).range([10, 100])
      // nonEmpties = nonEmpties.filter(n => n.id !== 'wikiperfum2')
      // let nodes = [rootNode, ...groups, ...nonEmpties]
      nodes.forEach(n => {
        n.r = Math.max(0, rScale(n.value))
        n.link_r = n.r + 5
      })
      nodes.forEach(s => {
        if (s.nodeType === 'GROUP') {
          s.centerX = s.x
          s.centerY = s.y
        } else if (s.nodeType === 'SUBGROUP') {
          let typeNode = s.parent
          s.centerX = typeNode.x
          s.centerY = typeNode.y
        } else if (s.nodeType === 'LEAF') {
          let typeNode = s.parent?.parent || s.parent || s
          s.centerX = typeNode.x
          s.centerY = typeNode.y
        }
      })
      let links = []
      nodes.forEach(n => {
        if (n.parent) {
          links.push({
            source: n,
            target: n.parent,
          })
        }
        // if (n.nodeType === 'LEAF') {
        //   links.push({
        //     source: n,
        //     target: groups.find(g => g.id === n.retailer),
        //   })
        // }
        // if (n.nodeType === 'GROUP') {
        //   links.push({
        //     source: n,
        //     target: rootNode,
        //   })
        // }
      })

      let canvas = this.$refs.canvas

      canvas.links = links

      let renderLinks = () => {
        canvas.links.forEach(link => {
          let c1 = link.source
          let c2 = link.target
          c2 = { ...c2, r: c2.link_r }
          c1 = { ...c1, r: c1.link_r }
          let tangents = common_tangent_line(c1.x, c1.y, c1.r, c2.x, c2.y, c2.r)
          let center = {
            x: c1.x + (c2.x - c1.x) * (c1.r / (c1.r + c2.r)),
            y: c1.y + (c2.y - c1.y) * (c1.r / (c1.r + c2.r)),
          }
          let path = []
          path.push('M', tangents[0][0][0], tangents[0][0][1])
          path.push(
            'C',
            center.x,
            center.y,
            center.x,
            center.y,
            tangents[1][1][0],
            tangents[1][1][1]
          )
          path.push('A', c2.r, c2.r, 0, 1, 1, tangents[0][1][0], tangents[0][1][1])
          path.push(
            'C',
            center.x,
            center.y,
            center.x,
            center.y,
            tangents[1][0][0],
            tangents[1][0][1]
          )
          path.push('A', c1.r, c1.r, 0, 1, 1, tangents[0][0][0], tangents[0][0][1])
          context.beginPath()
          context.fillStyle = c1.color || c2.color //link.target.data.family.color
          context.globalCompositeOperation = 'xor'
          context.globalAlpha = 0.6
          context.fill(new Path2D(path.join(' ')))
          context.globalAlpha = 1
          // context.stroke(new Path2D(path.join(' ')))
        })
      }
      let renderNodes = () => {
        simulation.nodes().forEach(node => {
          // if (!this.selectedIngredient || node.linked || node === this.selectedIngredient)
          //   context.globalAlpha = 1
          // else context.globalAlpha = 0.3
          let paddedR = node.r
          // if (node.type === 'GROUP') paddedR = 40
          context.beginPath()
          context.moveTo(node.x + paddedR, node.y)
          context.arc(node.x, node.y, paddedR, 0, 2 * Math.PI)
          // context.strokeStyle = '#fff'
          context.fillStyle = '#fff'
          context.fill()
          // paddedR = node.r - 5
        })
        context.globalCompositeOperation = 'darken'
        context.fillStyle = '#333'
        // let getName = node => {
        //   return node ? getName(node.parent) + '>' + node.id : ''
        // }
        let getName = node => {
          let name = node.name || node.label || node.id
          return name[0].toUpperCase() + name.slice(1).toLowerCase()
        }
        simulation.nodes().forEach(node => {
          if (this.selection?.id === node.id) context.globalAlpha = 0.9
          else context.globalAlpha = 0.5
          if (node.nodeType === 'GROUP' || node.nodeType === 'ROOT') {
            // context.globalAlpha = 0.2

            context.textAlign = 'center'
            context.textBaseline = 'middle'
            context.font = '22px serif'
            context.fillText(getName(node), node.x, node.y)
            context.fillText(this.prettyValue(node.value), node.x, node.y + 28)
            // context.fillText(this.prettyValue(node.value) + '-' + node.r, node.x, node.y + 28)
          } else if (this.selection?.id === node.id) {
            // context.globalAlpha = 0.9

            context.font = '22px serif'
            context.textAlign = 'center'
            context.textBaseline = 'middle'
            context.fillText(getName(node), node.x, node.y)
            context.fillText(this.prettyValue(node.value), node.x, node.y + 18)
            // context.fillText(this.prettyValue(node.value) + '-' + node.r, node.x, node.y + 8)
          }
        })
      }
      let render = () => {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)
        //bounds
        simulation.nodes().forEach(d => {
          let paddedR = d.r + 10
          d.x = Math.max(paddedR, Math.min(this.size.width - paddedR, d.x))
          d.y = Math.max(paddedR, Math.min(this.size.height - paddedR, d.y))
        })
        renderLinks()
        renderNodes()
      }

      const context = canvas.getContext('2d')
      const simulation =
        canvas.simulation ||
        d3_forceSimulation().velocityDecay(0.5).alphaDecay(0.005).on('tick', render)
      canvas.simulation = simulation
      this.$refs.canvas.onclick = e => {
        let coords = d3_pointer(e)
        let node = simulation.find(coords[0], coords[1], 50)
        if (node) {
          this.selection = node
        } else {
          this.selection = null
        }
        this.draw()
      }

      let oldNodes = simulation.nodes()
      nodes.forEach(newNode => {
        let candidate = oldNodes.find(n => n.id === newNode.id)
        if (candidate) {
          newNode.x = candidate.x
          newNode.y = candidate.y
        } else {
          newNode.x = newNode.centerX + (Math.random() * 100 - 50)
          newNode.y = newNode.centerY + (Math.random() * 100 - 50)
        }
      })
      d3_select(context.canvas).call(drag(nodes))
      function drag(circles) {
        // Choose the circle that is closest to the pointer for dragging.
        function dragsubject(event) {
          let subject = null
          let distance = Infinity
          for (const c of circles) {
            var r = 0
            if (c.nodeType === 'ROOT') r = 0
            else if (c.nodeType === 'GROUP') r = c.r + 40
            else if (c.nodeType === 'SUBGROUP') r = c.r + 40
            else r = c.r + 10
            let d = Math.hypot(event.x - c.x, event.y - c.y)
            if (d < distance && d < r) {
              distance = d
              subject = c
            }
          }
          return subject
        }
        function dragged(event) {
          event.subject.x = event.x
          event.subject.y = event.y
          simulation.alpha(0.2).restart()
        }
        return d3_drag().subject(dragsubject).on('drag', dragged)
      }

      // nodes.call(d3_drag()).on('drag', dragged)
      simulation.nodes(nodes)
      simulation
        .force(
          'collide',
          forceCollideOptional()
            .radius(d => {
              if (d.nodeType === 'ROOT') return 200
              else if (d.nodeType === 'GROUP') return d.r + 40
              else if (d.nodeType === 'SUBGROUP') return d.r + 40
              else return d.r + 10
            })
            .strength(0.8)
          // .strength((a, b) => {
          //   if (
          //     (a.linked && b === this.selectedIngredient) ||
          //     (b.linked && a === this.selectedIngredient)
          //   )
          //     return 0
          //   else return 0.6
          //   // return 0.6
          // })
        )
        .force(
          'forceX',
          d3_forceX()
            .x(d => d.centerX || this.size.width / 2)
            // .strength(d => (d.centerX !== null ? 0.5 : 0.02))
            .strength(d => (d.nodeType === 'GROUP' || d.nodeType === 'SUBGROUP' ? 1 : 0))
        )
        .force(
          'forceY',
          d3_forceY()
            .y(d => d.centerY || this.size.height / 2)
            // .strength(d => (d.centerY !== null ? 0.5 : 0.02))
            .strength(d => (d.nodeType === 'GROUP' || d.nodeType === 'SUBGROUP' ? 1 : 0))
        )
        .force(
          'charge',
          d3_forceManyBody().strength(() => {
            return -400
          })
        )
        .force(
          'link',
          d3_forceLink()
            .links(links)
            .distance(d => {
              return d.source.r + d.target.r + d.source.nodeType === 'GROUP' ? 240 : 40
            })
        )

      // if (!simulation.on('tick'))
      // simulation.on('tick', render)
      simulation.alpha(0.2).restart()
    },
    prettyValue(value) {
      if (this.dimension === 'vDuration') return this.$prettyDuration(value)
      else if (this.dimension === 'vBounceRate') return this.$prettyPercent(value)
      else return this.$prettyNumber(value) + ' ' + this.dimension.replace('v', '')
    },
  },
}
</script>

<style lang="stylus" scoped>
.sensor-network
  background: #fafafa
  height: 100%
  width: 100%
</style>