import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

/**
 * Return the response filtered by the playing date
 * @param {{date:Date}[]} response
 * @param {dayjs.Dayjs} timePlayingDate
 * @returns
 */
export function getResponseFromEvolutive(response, timePlayingDate) {
  const time = timePlayingDate.toDate()
  return response.filter(session => getDayFromDate(new Date(session.date)) === getDayFromDate(time))
}
/**
 * Return the day in order to filter the response.
 * ¡Maybe the performance is not the best if the response is big!
 * Issue #347: the limit is 200 days. If the limit is removed, we need to change this function
 * @param {Date} date
 * @returns {string}
 */
function getDayFromDate(date) {
  return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
}

/**
 * UTC is needed to avoid timezone issues when playing
 * @param {Date} date
 * @param {Number} range
 * @returns {dayjs.Dayjs}
 */
export function getPlayingDate(date, range) {
  var playingDate = dayjs(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).utc()
  playingDate = playingDate.date(playingDate.date() + Number(range))
  return playingDate.utc()
}
